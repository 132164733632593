import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import poifiltermixin from "./mixins/poifiltermixin";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import amenityLocalizations from "./assets/amenities.de.json";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);
Vue.component("l-popup", LPopup);

Vue.config.productionTip = false;

const localizeAmenitiesFunc = (amenity) => {
  const localization = amenityLocalizations.ameneties.find(
    (f) => f.key == amenity
  );
  if (localization == null) return amenity;
  return localization.value;
};

Vue.filter("localizeAmenities", localizeAmenitiesFunc);

Vue.filter("getPoiMapName", function (poi) {
  let name = poi.name;
  if (name == undefined || name === "") {
    name = poi.loc_name;
  }
  if (name == undefined || name === "") {
    name = localizeAmenitiesFunc(poi.amenity);
  }
  return name;
});

new Vue({
  mixins: [poifiltermixin],
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
