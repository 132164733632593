import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
// import Home2 from "../views/Home2.vue"
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/place/:id",
    component: () => import("../views/PoiDetail.vue"),
    props: true,
  },
  {
    path: "/tour/:id",
    component: () => import("../views/Tour.vue"),
    props: true,
  },
  {
    path: "/progress",
    component: () =>
      import(/* webpackChunkName: "progress" */ "../views/ProgressView.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/tourdownload/:tourId",
    component: () => import("../views/TourDownload.vue"),
    props: true,
  },
  {
    path: "/tour/:id/editpois",
    component: () => import("../views/TourPoiEdit.vue"),
    props: true,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
