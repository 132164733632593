import { mapGetters } from "vuex";
import { GET_CURRENT_FILTER } from "../store/modules/userstore";

export default {
  computed: {
    ...mapGetters({
      getCurrentFilter: "user/" + GET_CURRENT_FILTER,
    }),
  },
  methods: {
    filterVisiblePois(pois) {
      const selectedAmenities = this.getCurrentFilter.selectedAmenities;
      return this.filterPois(pois, selectedAmenities);
    },
    countPoisWithFilter(pois, selectedAmenities) {
      return this.filterPois(pois, selectedAmenities).length;
    },
    filterPois(pois, selectedAmenities) {
      if (selectedAmenities.length === 0 || pois.length === 0) return pois;
      return pois.filter((poi) => {
        return (
          selectedAmenities.findIndex((amenity) => amenity === poi.amenity) !==
          -1
        );
      });
    },
  },
};
