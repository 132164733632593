<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          block
          elevation="0"
          small
          text
          color="primary"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-map-legend</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="naviageToGMaps()">
          <v-list-item-avatar>
            <v-img :src="googleLogo" contain></v-img>
          </v-list-item-avatar>
          <v-list-item-title> Google Maps </v-list-item-title>
        </v-list-item>
        <v-list-item @click="naviageToOsm()">
          <v-list-item-avatar>
            <v-img :src="osmLogo" contain></v-img>
          </v-list-item-avatar>
          <v-list-item-title> OpenStreetMap </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
export default {
  name: "PoiLinkToExternalProviderButton",
  props: {
    poi: {
      type: Object,
    },
  },
  data: () => ({
    menu: false,
    googleLogo: require("../../assets/ThirdParty/gmaps_logo.svg"),
    osmLogo: require("../../assets/ThirdParty/osm_logo.svg"),
  }),
  methods: {
    getMapsUrl() {
      const lonLat = `${this.poi.lat},${this.poi.lon}`;
      return `https://www.google.com/maps/search/?api=1&query=${encodeURI(
        lonLat
      )}`;
    },
    getOsmUrl() {
      return `https://www.openstreetmap.org/#map=18/${this.poi.lat}/${this.poi.lon}`;
    },
    naviageToGMaps() {
      this.redirect(this.getMapsUrl());
    },
    naviageToOsm() {
      this.redirect(this.getOsmUrl());
    },
    redirect: function (link, target = "_blank") {
      window.open(link, target);
    },
  },
};
</script>
<style scoped></style>
