import { EventBus } from "../eventBus";
//note: This mixin needs to be added to PAGE components (ONLY works with pages!)
export const backButtonPageMixin = {
  data() {
    return {
      dialogOpen: false,
    };
  },
  mounted() {
    EventBus.$on("dialogOpen", () => {
      console.log("dialogOpen");
      this.dialogOpen = true;
    });
    EventBus.$on("dialogClosed", () => {
      console.log("dialogClosed");
      this.dialogOpen = false;
    });
  },
  beforeDestroy() {
    EventBus.$off("dialogOpen");
    EventBus.$off("dialogClosed");
  },
  beforeRouteUpdate(to, from, next) {
    if (this.dialogOpen) {
      EventBus.$emit("closeDialog");
      next(false);
    } else {
      next();
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.dialogOpen) {
      EventBus.$emit("closeDialog");
      next(false);
    } else {
      next();
    }
  },
};
