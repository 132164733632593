<template>
  <div class="mapelement">
    <l-map
      class="mapelement"
      v-if="showMap"
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      :maxBounds="maxBounds"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
      style="z-index: 0"
    >
      <l-control class="userLocationButton">
        <v-btn elevation="2" fab>
          <v-icon large @click="getLocation()"> mdi-crosshairs-gps </v-icon>
        </v-btn>
      </l-control>
      <l-tile-layer :url="url" :attribution="attribution" />
      <l-circle
        v-if="userLocation.locationReady"
        :lat-lng="[userLocation.lat, userLocation.lon]"
        :radius="100"
        :color="circleColor"
      >
        <l-tooltip :content="getUserLocationTooltip()" />
      </l-circle>
      <v-marker-cluster
        :options="clusterOptions"
        @clusterclick="click()"
        @ready="ready"
      >
        <l-marker
          v-for="item in pois"
          v-bind:key="item.id"
          :lat-lng="getCoordinates(item)"
        >
          <l-popup>
            <slot v-bind:poi="item"> </slot>
          </l-popup>
        </l-marker>
      </v-marker-cluster>
    </l-map>
  </div>
</template>

<script>
import { latLng, latLngBounds } from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LCircle,
  LControl,
  LTooltip,
} from "vue2-leaflet";
import Vue2LeafletMarkercluster from "vue2-leaflet-markercluster";
import { createNamespacedHelpers } from "vuex";
import {
  FETCH_LOCATION_ASYNC,
  GET_USER_LOCATION,
} from "../store/modules/userstore";

const { mapGetters, mapState, mapActions } = createNamespacedHelpers("user");

export default {
  name: "PoiMap",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LCircle,
    LControl,
    LTooltip,

    "v-marker-cluster": Vue2LeafletMarkercluster,
  },
  props: {
    pois: Array,
    center: {
      type: Object,
      default: function () {
        return latLng(50.97923191430942, 11.026933864301782);
      },
    },
    zoom: {
      type: Number,
      default: 11,
    },
    showDetailDialog: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 11.5,
      currentCenter: latLng(47.41322, -1.219482),
      mapOptions: {
        zoomSnap: 0.5,
      },
      showMap: true,
      maxBounds: latLngBounds([
        [50.7, 10.75],
        [51.2, 11.3],
      ]),
      error: "",
      circleColor: "blue",
      clusterOptions: {},
    };
  },
  computed: {
    ...mapState(["userLocation"]),
    ...mapGetters([GET_USER_LOCATION]),
  },
  methods: {
    ...mapActions([FETCH_LOCATION_ASYNC]),
    getCoordinates(poi) {
      return latLng(poi.lat, poi.lon);
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    innerClick() {
      alert("Click!");
    },
    async getLocation() {
      await this.FETCH_LOCATION_ASYNC();
    },
    click: (e) => console.log("clusterclick", e),
    ready: (e) => console.log("ready", e),
    getUserLocationTooltip() {
      if (this.userLocation === null) return;

      if (this.userLocation.source === "browser") {
        return "Dein aktueller Standort.";
      }
      return "Dein geschätzter Standort.";
    },
  },
  mounted() {
    setTimeout(() => {
      console.log("done");
      this.$nextTick(() => {
        this.clusterOptions = { disableClusteringAtZoom: 14 };
      });
    }, 5000);
  },
};
</script>
<style scoped>
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
.userLocationButton {
  padding: 0 0 0.5em;
}
.mapelement {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
