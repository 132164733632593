<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn block elevation="0" small outlined v-bind="attrs" v-on="on">
          <v-icon>mdi-information-outline</v-icon>
          {{ getButtonLabel() }}
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Details</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-title>
          <poi-title :poi="poi" />
        </v-card-title>
        <v-card-text v-if="getOutdoorSeating()">
          Sitzplätze im Freien: {{ getOutdoorSeating() }}
        </v-card-text>
        <v-card-text>
          <poi-opening-hours :poi="poi" />
          <poi-contact-and-info :poi="poi" />
        </v-card-text>
        <!-- <v-card-text>
          <poi-map
            :v-if="showMap"
            :pois="[poi]"
            style="height: 30vh"
            :center="getLocationCoordinates()"
            :zoom="15"
            :showDetailDialog="false"
          >
            <template v-slot:default="slotProps">
              <poi-title :poi="slotProps.poi" />
            </template>
          </poi-map>
        </v-card-text> -->
        <v-card-actions>
          <poi-share-button :poi="poi" />
          <poi-bookmark-button :poi="poi" />
          <poi-visit-button :poiId="poi.id" />
          <poi-link-to-external-provider-button :poi="poi" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { backButtonDialogMixin } from "../mixins/backButtonDialogMixin";

import PoiTitle from "./poi/PoiTitle.vue";
import PoiBookmarkButton from "../components/poi/PoiBookmarkButton.vue";
import PoiContactAndInfo from "../components/poi/PoiContactAndInfo.vue";
import PoiOpeningHours from "../components/poi/PoiOpeningHours.vue";
import PoiShareButton from "../components/poi/PoiShareButton.vue";
import PoiVisitButton from "../components/poi/PoiVisitButton.vue";
// import PoiMap from "../components/PoiMap.vue";
import { latLng } from "leaflet";
import PoiLinkToExternalProviderButton from "./poi/PoiLinkToExternalProviderButton.vue";

export default {
  components: {
    PoiContactAndInfo,
    PoiOpeningHours,
    PoiVisitButton,
    PoiShareButton,
    PoiBookmarkButton,
    // PoiMap,
    PoiTitle,
    PoiLinkToExternalProviderButton,
  },
  name: "PoiDetailDialog",
  mixins: [backButtonDialogMixin],
  props: {
    poi: {
      type: Object,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    showMap: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      label: "Details",
    };
  },
  watch: {
    dialog: "notifyDialogStateViaEventBus",
  },
  computed: {},
  methods: {
    getOutdoorSeating() {
      return this.poi.outdoor_seating;
    },
    getButtonLabel() {
      if (this.hideLabel === true) {
        return "";
      }
      return this.label;
    },
    getLocationCoordinates() {
      return latLng(this.poi.lat, this.poi.lon);
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>
<style></style>
