<template>
  <div class="home">
    <poi-map :pois="filterVisiblePois(GET_POIS)">
      <template v-slot:default="slotProps">
        <poi-title :poi="slotProps.poi" />
        <poi-detail-dialog :poi="slotProps.poi" style="margin: 0px" />
      </template>
    </poi-map>
  </div>
</template>

<script>
import PoiMap from "../components/PoiMap";
import PoiTitle from "../components/poi/PoiTitle.vue";
import PoiDetailDialog from "../components/PoiDetailDialog.vue";
import { GET_POIS } from "../store/modules/poistore";
import { createNamespacedHelpers } from "vuex";
import poifiltermixin from "../mixins/poifiltermixin";
import { backButtonPageMixin } from "../mixins/backButtonPageMixin";
const { mapGetters } = createNamespacedHelpers("pois");

export default {
  name: "Home",
  mixins: [poifiltermixin, backButtonPageMixin],
  components: {
    PoiMap,
    PoiTitle,
    PoiDetailDialog,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([GET_POIS]),
  },
};
</script>
<style>
.home {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
