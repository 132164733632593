import poiapiservice from "../../services/poiapiservice";
import storageservice from "../../services/storageservice";
import * as storagekey from "../../services/storagekeys";
import {
  GET_POI,
  GET_POIS,
  GET_POIS_LOADED,
  GET_AMENITIES,
  SET_POIS_LOADED,
  SET_POIS,
  SET_AMENITIES,
  INIT_POIS,
} from "./poistore";

export default {
  namespaced: true,
  state: () => ({
    /* eslint-disable no-undef */
    apiBase: process.env.VUE_APP_API_BASE,
    pois: storageservice.readFromStorage(storagekey.POIS) || [],
    amenities: storageservice.readFromStorage(storagekey.AMENITIES) || [],
    poisLoaded: false,
  }),
  mutations: {
    [SET_POIS](state, pois) {
      state.pois = pois;
    },
    [SET_AMENITIES](state, amenities) {
      state.amenities = amenities;
    },
    [SET_POIS_LOADED](state, loaded) {
      state.poisLoaded = loaded;
    },
  },
  actions: {
    async [INIT_POIS]({ state, commit }) {
      // we got them from local storage
      if (state.pois.length > 0) {
        commit(SET_POIS_LOADED, true);
      }
      poiapiservice.init(state.apiBase);
      const response = await poiapiservice.getPois();
      commit(SET_POIS, response.data);
      const amenities = Array.from(
        new Set(response.data.map((item) => item.amenity))
      );
      commit(SET_AMENITIES, amenities);
      commit(SET_POIS_LOADED, true);
    },
  },
  getters: {
    [GET_POIS]: (state) => {
      return state.pois;
    },
    [GET_AMENITIES]: (state) => {
      return state.amenities;
    },
    [GET_POI]: (state) => {
      return (id) =>
        state.pois.find((item) => {
          return item.id == id;
        });
    },
    [GET_POIS_LOADED]: (state) => {
      return state.poisLoaded;
    },
  },
};
