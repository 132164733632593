export const SET_LOCATION = "SET_LOCATION";
export const SET_FILTERED_AMENITIES = "SET_FILTERED_AMENITIES";
export const SET_VISITED_POIS = "SET_VISITED_POIS";
export const SET_TOURS = "SET_TOURS";
export const TOGGLE_POI_ID_VISITED = "TOGGLE_POI_ID_VISITED";
export const FETCH_LOCATION_ASYNC = "FETCH_LOCATION_ASYNC";
export const CREATE_TOUR = "CREATE_TOUR";
export const UPDATE_TOUR = "UPDATE_TOUR";
export const REMOVE_TOUR = "REMOVE_TOUR";
export const ADD_DOWNLOADED_TOUR = "ADD_DOWNLOADED_TOUR";
export const TOGGLE_POI_IN_TOUR = "TOGGLE_POI_IN_TOUR";
export const IS_POI_IN_TOUR = "IS_POI_IN_TOUR";
export const GET_USER_LOCATION = "GET_USER_LOCATION";
export const GET_CURRENT_FILTER = "GET_CURRENT_FILTER";
export const GET_IS_POI_VISITED = "GET_IS_POI_VISITED";
export const GET_VISITED_POIS = "GET_VISITED_POIS";
export const GET_DEFAULT_TOUR = "GET_DEFAULT_TOUR";
export const GET_TOURS = "GET_TOURS";
export const GET_TOUR = "GET_TOUR";
export const GET_TOUR_BY_EXTERN_ID = "GET_TOUR_BY_EXTERN_ID";
export const GET_API_SERVICE = "GET_API_SERVICE";
