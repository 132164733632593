import axios from "axios";

const axiosSettings = {
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 60000,
};

let apiClient = null;

export default {
  init(baseURL) {
    console.log(baseURL);
    apiClient = axios.create({
      ...axiosSettings,
      //baseURL,
      //TODO remove again
      // baseURL: "http://127.0.0.1:8787/api/",
      baseURL: "https://poivisitor-api.fwo-code.workers.dev/api/",
    });
  },
  async getPois() {
    return await apiClient.get("pois/");
  },
  async getUserLocation() {
    return await apiClient.get("location/");
  },
  async getTour(tourId) {
    return await apiClient.get(`tour?id=${tourId}`);
  },
  async uploadTour(tour) {
    return await apiClient.post("tour/upload", JSON.stringify(tour));
  },
};
