import { EventBus } from "../eventBus";
export const backButtonDialogMixin = {
  /*
    in component import the mixin
    import {mobileBackButtonDialogComponentMixin} from "@/mixins/mobileBackButtonDialogComponentMixin";
    mixins: [mobileBackButtonDialogComponentMixin],
    then add the following (you can add multiple dialog props)
    replace "myDialogProp" with the name of data property that is used in v-model of v-dialog
    watch: {
        myDialogProp: 'notifyDialogStateViaEventBus'
    },
    methods: {
        closeDialogs(){
            this.myDialogProp = false;
        }
    }
    */
  methods: {
    notifyDialogStateViaEventBus(open) {
      console.log(open);
      console.log(EventBus);
      if (open) {
        EventBus.$emit("dialogOpen");
        EventBus.$on("closeDialog", () => {
          this.closeDialog();
        });
      } else {
        EventBus.$emit("dialogClosed");
        EventBus.$off("closeDialog");
      }
    },
  },
};
