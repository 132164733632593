import storageservice from "../../services/storageservice";
import * as storagekey from "../../services/storagekeys";
import { SET_AMENITIES, SET_POIS } from "../modules/poistore";
import { SET_TOURS, SET_VISITED_POIS } from "../modules/userstore";
export default function persistStatePlugin() {
  return (store) => {
    store.subscribe((mutation) => {
      //TODO Refactor -> will get out of hand once we store more states
      if (mutation.type === "pois/" + SET_POIS) {
        storageservice.saveToStorage(storagekey.POIS, mutation.payload);
      }
      if (mutation.type === "pois/" + SET_AMENITIES) {
        storageservice.saveToStorage(storagekey.AMENITIES, mutation.payload);
      }
      if (mutation.type === "user/" + SET_VISITED_POIS) {
        storageservice.saveToStorage(storagekey.USERVISITS, mutation.payload);
      }
      if (mutation.type === "user/" + SET_TOURS) {
        storageservice.saveToStorage(storagekey.USERTOURS, mutation.payload);
      }
    });
  };
}
