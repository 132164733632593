import poiapiservice from "../../services/poiapiservice";
import storageservice from "../../services/storageservice";
import * as storagekey from "../../services/storagekeys";
import { nanoid } from "nanoid";

const getPoiIndexInTour = (tour, poiId) => {
  return tour.poiIds.findIndex((id) => poiId == id);
};

const getIndexOfTour = (tours, tourId) => {
  return tours.findIndex((tour) => tour.id == tourId);
};

import {
  SET_LOCATION,
  SET_FILTERED_AMENITIES,
  SET_TOURS,
  SET_VISITED_POIS,
  TOGGLE_POI_ID_VISITED,
  FETCH_LOCATION_ASYNC,
  CREATE_TOUR,
  UPDATE_TOUR,
  REMOVE_TOUR,
  ADD_DOWNLOADED_TOUR,
  GET_USER_LOCATION,
  GET_CURRENT_FILTER,
  GET_IS_POI_VISITED,
  GET_DEFAULT_TOUR,
  GET_TOURS,
  GET_TOUR,
  GET_TOUR_BY_EXTERN_ID,
  GET_API_SERVICE,
  GET_VISITED_POIS,
  TOGGLE_POI_IN_TOUR,
  IS_POI_IN_TOUR,
} from "./userstore";
export default {
  namespaced: true,
  state: {
    /* eslint-disable no-undef */
    apiBase: process.env.VUE_APP_API_BASE,
    userLocation: {
      lat: 0,
      lon: 0,
      source: "",
      locationReady: false,
    },
    currentFilter: {
      selectedAmenities: [],
    },
    persistedData: {
      visitedPois: storageservice.readFromStorage(storagekey.USERVISITS) || [],
      tourLists: storageservice.readFromStorage(storagekey.USERTOURS) || [],
      defaultTourList: {
        id: "",
        name: "",
        poiIds: [],
        externalId: "",
      },
    },
  },
  mutations: {
    [SET_LOCATION](state, userLocation) {
      state.userLocation = userLocation;
    },
    [SET_FILTERED_AMENITIES](state, amenities) {
      state.currentFilter.selectedAmenities = amenities;
    },
    [SET_VISITED_POIS](state, visitedPois) {
      state.persistedData.visitedPois = visitedPois;
    },
    [SET_TOURS](state, tourLists) {
      console.log(tourLists);
      state.persistedData.tourLists = tourLists;
    },
  },
  actions: {
    [TOGGLE_POI_ID_VISITED]({ state, commit }, poiId) {
      const index = state.persistedData.visitedPois.findIndex(
        (id) => id == poiId
      );
      let newState = state.persistedData.visitedPois;
      if (index !== -1) {
        newState.splice(index, 1);
      } else {
        newState.push(poiId);
      }

      commit(SET_VISITED_POIS, newState);
    },
    async [FETCH_LOCATION_ASYNC]({ state, commit }) {
      let showPosition = (position) => {
        commit(SET_LOCATION, {
          lat: position.coords.latitude,
          lon: position.coords.longitude,
          source: "browser",
          locationReady: true,
        });
      };
      let callCloudflare = async () => {
        poiapiservice.init(state.apiBase);
        const response = await poiapiservice.getUserLocation();
        commit(SET_LOCATION, {
          lat: response.data.latitude,
          lon: response.data.longitude,
          source: "cloudflare",
          locationReady: true,
        });
      };
      let errorCallback = async (error) => {
        console.log(error);
        await callCloudflare();
      };
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition, errorCallback);
      } else {
        await callCloudflare();
      }
    },
    [CREATE_TOUR]({ state, commit }, tourList) {
      //TODO remove magic number
      tourList.id = nanoid(10);
      console.log(tourList);
      let newState = [...state.persistedData.tourLists, tourList];
      // newState.push(tourList);
      console.log(newState);
      commit(SET_TOURS, newState);
    },
    [UPDATE_TOUR]({ state, commit }, tourList) {
      const index = getIndexOfTour(state.persistedData.tourLists, tourList.id);
      let newState = [...state.persistedData.tourLists];
      if (index === -1) {
        return;
      }
      newState[index] = tourList;
      commit(SET_TOURS, newState);
    },
    [REMOVE_TOUR]({ state, commit }, tourId) {
      const index = getIndexOfTour(state.persistedData.tourLists, tourId);
      let newState = [...state.persistedData.tourLists];
      if (index === -1) {
        return;
      }
      newState.splice(index, 1);
      commit(SET_TOURS, newState);
    },
    [ADD_DOWNLOADED_TOUR]({ state, commit }, tour) {
      const index = state.persistedData.tourLists.findIndex(
        (t) => t.externalId == tour.id
      );
      let newState = [...state.persistedData.tourLists];
      if (index === -1) {
        tour.externalId = tour.id;
        tour.id = nanoid(10);
        newState.push(tour);
      } else {
        tour.id = newState[index].id;
        tour.externalId = newState[index].externalId;
        newState[index] = tour;
      }
      commit(SET_TOURS, newState);
    },
    [TOGGLE_POI_IN_TOUR]({ state, commit }, element) {
      const poiId = element.poiId;
      const tourId = element.tourId;
      if (!poiId || !tourId) return;

      const tourIndex = getIndexOfTour(state.persistedData.tourLists, tourId);
      if (tourIndex < 0) return false;
      const tour = Object.assign({}, state.persistedData.tourLists[tourIndex]);
      tour.poiIds = tour.poiIds.slice();
      const poiIndex = getPoiIndexInTour(tour, poiId);
      if (poiIndex !== -1) {
        tour.poiIds.splice(poiIndex, 1);
      } else {
        tour.poiIds.push(poiId);
      }
      let newState = [...state.persistedData.tourLists];
      newState[tourIndex] = tour;
      commit(SET_TOURS, newState);
    },
  },
  getters: {
    [GET_USER_LOCATION]: (state) => {
      return state.userLocation;
    },
    [GET_CURRENT_FILTER]: (state) => {
      return state.currentFilter;
    },
    [GET_IS_POI_VISITED]: (state) => {
      return (poiId) => {
        const index = state.persistedData.visitedPois.findIndex(
          (id) => id == poiId
        );
        return index !== -1;
      };
    },
    [GET_VISITED_POIS]: (state) => {
      return state.persistedData.visitedPois;
    },
    [GET_DEFAULT_TOUR]: (state) => {
      //TODO
      const defaultState = state.persistedData.defaultTourList;
      const defaultList = { ...defaultState };
      defaultList.poiIds = [];
      defaultList.id = "";
      return {
        id: "",
        name: "",
        poiIds: [],
      };
    },
    [GET_TOURS]: (state) => {
      return state.persistedData.tourLists;
    },
    [GET_TOUR]: (state) => {
      return (tourId) => {
        const index = getIndexOfTour(state.persistedData.tourLists, tourId);
        if (index === -1) return null;
        return state.persistedData.tourLists[index];
      };
    },
    [GET_TOUR_BY_EXTERN_ID]: (state) => {
      return (tourId) => {
        const index = state.persistedData.tourLists.findIndex(
          (l) => l.externalId == tourId
        );
        if (index === -1) return null;
        return state.persistedData.tourLists[index];
      };
    },
    [GET_API_SERVICE]: (state) => {
      poiapiservice.init(state.apiBase);
      return poiapiservice;
    },
    [IS_POI_IN_TOUR]: (state) => (query) => {
      const tourId = query.tourId;
      const poiId = query.poiId;
      if (!poiId || !tourId) return false;
      const tourIndex = getIndexOfTour(state.persistedData.tourLists, tourId);
      if (tourIndex < 0) return false;
      const tour = state.persistedData.tourLists[tourIndex];
      if (!tour) return false;
      const poiIndex = getPoiIndexInTour(tour, poiId);
      return poiIndex !== -1;
    },
  },
};
