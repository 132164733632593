<template>
  <v-app class="app">
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Point of Interests Erfurt</v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>-->

      <v-btn icon @click="toggleOpenFilterDialog()">
        <v-icon>mdi-filter</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      top
      temporary
      style="
        z-index: 10000;
        position: fixed;
        top: 0;
        left: 0;
        overflow-y: scroll;
      "
    >
      <app-navigation />
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>

    <poi-filter :eventbus="eventbus" />

    <v-snackbar
      bottom
      right
      :value="updateExists"
      :timeout="-1"
      color="primary"
      style="z-index: 100000"
    >
      Eine Update ist verfügbar!
      <v-btn text @click="refreshApp">Updaten</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { INIT_POIS } from "./store/modules/poistore";
const { mapState, mapActions } = createNamespacedHelpers("pois");
import PoiFilter from "./components/PoiFilter.vue";
import AppNavigation from "./components/AppNavigation.vue";
import Vue from "vue";
import update from "./mixins/update";

export default {
  components: { AppNavigation, PoiFilter },
  name: "App",
  data: () => ({
    drawer: false,
    group: null,
    sheet: false,
    paths: [
      {
        name: "Start",
        path: "/",
      },
      {
        name: "About",
        path: "/about",
      },
    ],
    eventbus: new Vue(),
  }),
  mixins: [update],
  computed: {
    ...mapState(["pois"]),
  },
  methods: {
    ...mapActions([INIT_POIS]),
    toggleOpenFilterDialog() {
      this.eventbus.$emit("toggleOpen");
    },
  },
  async created() {
    try {
      await this.INIT_POIS();
    } catch (err) {
      console.log(err);
      alert("error loading pois");
    }
  },
};
</script>
<style>
.app {
  overflow: hidden;
}
</style>
