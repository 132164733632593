<template>
  <div v-if="poi && getOpeningHours()">
    <div class="text-h6">Öffnungszeiten</div>
    <div class="text-body-2">{{ getOpeningHours() }}</div>
  </div>
</template>
<script>
export default {
  name: "PoiOpeningHours",
  props: ["poi"],
  methods: {
    getOpeningHours() {
      return this.poi.opening_hours;
    },
  },
};
</script>
