<template>
  <v-checkbox
    v-model="poiAlreadyVisited"
    :label="getVisitedCheckboxText(poiAlreadyVisited)"
    :value="poiAlreadyVisited"
  >
  </v-checkbox>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
import {
  TOGGLE_POI_ID_VISITED,
  GET_IS_POI_VISITED,
} from "../../store/modules/userstore";
const user = createNamespacedHelpers("user");

export default {
  name: "PoiVisitButton",
  props: ["poiId", "hideLabel"],
  computed: {
    ...user.mapGetters([GET_IS_POI_VISITED]),
    poiAlreadyVisited: {
      get() {
        return this.GET_IS_POI_VISITED(this.poiId);
      },
      set(value) {
        console.log(`poiAlreadyVisited ${value}`);
        this.TOGGLE_POI_ID_VISITED(this.poiId);
      },
    },
  },
  methods: {
    ...user.mapActions([TOGGLE_POI_ID_VISITED]),
    getVisitedCheckboxText(visited) {
      if (this.hideLabel === true) {
        return "";
      }
      return visited ? "Bereits besucht" : "Noch nicht besucht";
    },
  },
};
</script>
