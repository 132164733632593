<template>
  <div v-if="poi && showContact()">
    <div class="text-h6">Kontakt und Informationen</div>
    <div class="text-body-2 p-2" v-if="getPhone()">
      Telefon:
      <a :href="'tel:' + getPhone()">{{ getPhone() }}</a>
    </div>
    <div class="text-body-2 p-2" v-if="getEmail()">
      E-Mail:
      <a :href="'mailto:' + getEmail()">{{ getEmail() }}</a>
    </div>
    <div class="text-body-2 p-2" v-if="getWebsite()">
      Website:
      <a :href="'//' + getWebsiteHref()" target="_blank">{{ getWebsite() }}</a>
    </div>
  </div>
</template>
<script>
export default {
  name: "PoiContactAndInfo",
  props: ["poi"],
  methods: {
    showContact() {
      const poi = this.poi;
      return poi.phone || poi.website || poi.email;
    },
    getPhone() {
      return this.poi.phone;
    },
    getEmail() {
      return this.poi.email;
    },
    getWebsite() {
      console.log(this.poi.website);
      return this.poi.website;
    },
    getWebsiteHref() {
      return this.poi.website.replace("https://", "").replace("http://", "");
    },
  },
};
</script>
