<template>
  <v-bottom-sheet v-model="sheet" style="z-index: 10000">
    <v-sheet class="text-center" style="z-index: 10000">
      <v-btn class="mt-6" text color="red" @click="onClose()">Schließen</v-btn>
      <v-container>
        <v-row no-gutters>
          <template v-for="(e, index) in getAmenities">
            <v-col :key="index">
              <v-checkbox
                :key="index"
                v-model="selectedAmenities"
                :value="e"
                :label="e | localizeAmenities"
              >
              </v-checkbox>
            </v-col>
          </template>
        </v-row>
        <!-- <v-divider inset></v-divider> -->
        <v-row align="center" justify="center" no-gutters>
          <v-btn @click="useFilters()">Filter Anwenden</v-btn>
        </v-row>
      </v-container>
    </v-sheet>
  </v-bottom-sheet>
</template>
<script>
import { mapMutations, mapGetters } from "vuex";
import poifiltermixin from "../mixins/poifiltermixin";
import { GET_AMENITIES } from "../store/modules/poistore";
import {
  SET_FILTERED_AMENITIES,
  GET_CURRENT_FILTER,
} from "../store/modules/userstore";
export default {
  name: "PoiFilter",
  props: ["eventbus"],
  mixins: [poifiltermixin],
  data() {
    return {
      selectedAmenities: [],
      sheet: false,
    };
  },
  computed: {
    ...mapGetters({
      getAmenities: "pois/" + GET_AMENITIES,
      getCurrentFilter: "user/" + GET_CURRENT_FILTER,
    }),
  },
  methods: {
    ...mapMutations({
      setFilteredAmenities: "user/" + SET_FILTERED_AMENITIES,
    }),
    useFilters() {
      this.setFilteredAmenities(this.selectedAmenities);
      this.toggleOpen();
    },
    onClose() {
      this.selectedAmenities = this.getCurrentFilter.selectedAmenities;
      this.toggleOpen();
    },
    toggleOpen() {
      this.sheet = !this.sheet;
    },
  },
  mounted() {
    this.eventbus.$on("toggleOpen", this.toggleOpen);
  },
};
</script>
<style></style>
