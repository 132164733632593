import Vue from "vue";
import Vuex from "vuex";
import pois from "./modules/pois";
import user from "./modules/user";
import persistStatePlugin from "./plugins/persistStatePlugin.js";

Vue.use(Vuex);

const plugin = persistStatePlugin();

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    pois,
    user,
  },
  plugins: [plugin],
});
