<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <!-- <v-btn color="primary" text></v-btn> -->
        <v-btn color="primary" text v-bind="attrs" v-on="on">
          <v-icon>{{
            isPoiInAnyTour() ? "mdi-bookmark" : "mdi-bookmark-outline"
          }}</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item v-for="item in GET_TOURS" v-bind:key="item.id">
            <v-list-item-action>
              <v-switch
                :input-value="isInTourList(item.id)"
                color="primary"
                @change="togglePoiInList(item.id)"
              ></v-switch>
            </v-list-item-action>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
            <v-list-item-action>
              <v-btn text @click="removeItem(item.id)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <v-list-item>
            <v-text-field
              v-model.trim="newTourListName"
              :append-outer-icon="'mdi-check'"
              filled
              clear-icon="mdi-close-circle"
              clearable
              label="Tourname"
              type="text"
              @click:append-outer="createNewTourList"
            ></v-text-field>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
import {
  GET_TOURS,
  GET_TOUR,
  GET_DEFAULT_TOUR,
  CREATE_TOUR,
  UPDATE_TOUR,
  REMOVE_TOUR,
} from "../../store/modules/userstore";
const { mapGetters, mapActions } = createNamespacedHelpers("user");

export default {
  name: "PoiBookmarkButton",
  props: {
    poi: {
      type: Object,
    },
  },
  data: () => ({
    menu: false,
    newTourListName: "",
  }),
  computed: {
    ...mapGetters([GET_TOURS, GET_TOUR, GET_DEFAULT_TOUR]),
  },
  methods: {
    ...mapActions([CREATE_TOUR, UPDATE_TOUR, REMOVE_TOUR]),
    isInTourList(tourId) {
      const tour = this.GET_TOUR(tourId);
      if (tour == null || tour == undefined) return false;
      const isInList = this.getIndexOfCurrentPoi(tour.poiIds) !== -1;
      return isInList;
    },
    getIndexOfCurrentPoi(poiIds) {
      return poiIds.findIndex((poiId) => poiId == this.poi.id);
    },
    isPoiInAnyTour() {
      return this.GET_TOURS.some((tour) => this.isInTourList(tour.id));
    },
    togglePoiInList(tourId) {
      //TODO maybe null checks
      const tourList = this.GET_TOUR(tourId);
      const poiIndex = this.getIndexOfCurrentPoi(tourList.poiIds);
      if (poiIndex !== -1) {
        tourList.poiIds.splice(poiIndex, 1);
      } else {
        tourList.poiIds.push(this.poi.id);
      }
      console.log(tourList.poiIds);
      this.UPDATE_TOUR(tourList);
    },
    createNewTourList() {
      if (this.newTourListName == "") return;
      let tourList = { ...this.GET_DEFAULT_TOUR };
      tourList.name = this.newTourListName;
      tourList.poiIds = [];
      tourList.poiIds.push(this.poi.id);
      this.CREATE_TOUR(tourList);
      this.newTourListName = "";
    },
    removeItem(tourId) {
      this.REMOVE_TOUR(tourId);
    },
  },
};
</script>
