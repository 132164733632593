<template>
  <!---->

  <v-list nav dense>
    <template v-for="(item, index) in pagesStart">
      <v-list-item :key="index" :to="item.url">
        <v-list-item-title>
          {{ item.title }}
        </v-list-item-title>
      </v-list-item>
    </template>
    <v-list-group no-action>
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>Touren</v-list-item-title>
        </v-list-item-content>
      </template>
      <v-list-item
        v-for="item in GET_TOURS"
        :key="item.id"
        link
        :to="getTourLink(item)"
      >
        <v-list-item-title>{{ item.name }}</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <template v-for="(item, index) in pagesEnd">
      <v-list-item :key="index + pagesStart.length" :to="item.url">
        <v-list-item-title>
          {{ item.title }}
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { GET_TOURS } from "../store/modules/userstore";
const { mapGetters } = createNamespacedHelpers("user");

export default {
  name: "AppNavigation",
  props: {
    showDrawer: Boolean,
  },
  data() {
    return {
      drawer: false,
      admins: [
        ["Management", "mdi-account-multiple-outline"],
        ["Settings", "mdi-cog-outline"],
      ],
      pagesStart: [
        { title: "Start", url: "/" },
        { title: "Fortschritt", url: "/progress" },
      ],
      pagesEnd: [{ title: "Über die Seite", url: "/about" }],
    };
  },
  computed: {
    ...mapGetters([GET_TOURS]),
  },
  watch: {
    // whenever question changes, this function will run
    showDrawer: function (draw) {
      this.drawer = draw;
    },
  },
  methods: {
    getTourLink(tour) {
      return `/tour/${tour.id}`;
    },
  },
};
</script>
