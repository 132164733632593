<template>
  <div>
    <v-btn color="primary" text @click="share()"
      ><v-icon>mdi-share-variant</v-icon></v-btn
    >
    <v-snackbar v-model="showSnackbar" :timeout="snackbarTimeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="showSnackbar = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  name: "PoiShareButton",
  props: {
    poi: {
      type: Object,
    },
  },
  data() {
    return {
      showSnackbar: false,
      snackbarText: "",
      snackbarTimeout: 1000,
    };
  },
  methods: {
    getUrl() {
      //TODO fragile and needs better implementations
      let getUrl = window.location;
      let url = getUrl.protocol + "//" + getUrl.host; /* +
        "/" +
        getUrl.pathname.split("/")[1]*/
      if (url.includes(`place/${this.poi.id}`)) {
        return url;
      }
      return url + `/place/${this.poi.id}`;
    },
    async share() {
      if (navigator.share) {
        await this.shareViaWebShare();
        return;
      }
      await this.shareOldSchool();
    },
    async shareViaWebShare() {
      const url = this.getUrl();
      await navigator
        .share({
          title: "Title to be shared",
          text: url,
          url: url,
        })
        .then(
          () => console.log("Successful share"),
          (error) => console.log("Error sharing:", error)
        );
    },
    async shareOldSchool() {
      var get_current_url = this.getUrl();
      await navigator.clipboard
        .writeText(get_current_url)
        .then(() => {
          this.showSnackbar = true;
          this.snackbarText = `${get_current_url} in Zwischenablage kopiert`;
        })
        .catch((err) => {
          this.snackbarText = `Error in copying text: ${err}`;
        });
    },
  },
};
</script>
