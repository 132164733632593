<template>
  <div v-if="poi">
    <div class="text-h5">
      {{ poi | getPoiMapName }}
    </div>
    <div v-if="poi.loc_name && poi.name" class="text-h5">
      {{ poi.loc_name }}
    </div>
    <div class="text-subtitle-1">
      {{ poi.amenity | localizeAmenities }}
    </div>
  </div>
</template>
<script>
export default {
  name: "PoiTitle",
  props: ["poi"],
};
</script>
